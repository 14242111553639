import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import useBenguide from 'hooks/benguide';
import EditableHotspot from 'components/EditableHotspot/EditableHotspot';
import useUPClient from 'modules/clients/UPClient/useUPClient';
import PlanCardEditor from 'modules/benefitsWellness/components/PlanCardEditor';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  getRichMediaContent,
  updateRichMediaContent,
} from 'modules/benefitsWellness/slices/voluntaryBenefitsSlice';
import ContentPreviewer from 'components/ContentPreviewer/ContentPreviewer';
import {
  OPEN_VOLUNTARY_BENEFITS_EDIT_MODAL,
  VOLUNTARY_BENEFITS_CHANNEL,
} from 'modules/clients/UPClient/UPClientConst';
import iconEdit from 'images/icon-edit.svg';
import VoluntaryBenefitsPlan from 'model/VoluntaryBenefitsPlan';
import TooltipPopover from 'components/TooltipPopover/TooltipPopover';
import Spinner from 'components/Spinner/Spinner';
import AddYourText from 'modules/benefitsWellness/components/AddYourText/AddYourText';
import { SectionName } from 'modules/home/constants';
import { benefitCategory } from 'modules/auth/constants/commonConstants';
import PlanCardContainer from 'modules/benefitsWellness/components/PlanCardContainer/PlanCardContainer';
import BenefitCarrier from 'model/BenefitCarrier';

import ViewResourcesModal from 'components/ViewResourcesModal/ViewResourcesModal';
import { VoluntaryPlanTag } from 'modules/benefitsWellness/components/VoluntaryTag/voluntaryPlanTag';
import styles from './voluntaryBenefitsCard.module.less';

type VoluntaryBenefitsCardProps = {
  plan: VoluntaryBenefitsPlan;
};

const VoluntaryBenefitsCard: FC<VoluntaryBenefitsCardProps> = ({
  plan,
}: VoluntaryBenefitsCardProps) => {
  const upClient = useUPClient();
  const benguide = useBenguide();
  const dispatch = useAppDispatch();
  const { isTablet } = useAppSelector((state) => state.layout);
  const { richMedia } = useAppSelector((state) => state.voluntaryBenefits);
  const defaultRichMedia = useAppSelector(
    (state) => state.voluntaryBenefits.defaultRichMedia.data
  );
  const { masterId, isEditMode } = benguide;
  const defaultContent = '';
  const { id, benefitKind, benefitCarrier = {} as BenefitCarrier } = plan;
  const { benefitDescription = '' } = benefitCarrier;
  const richMediaForPlan = get(richMedia, id);
  const { content } = richMediaForPlan || {};

  const isContentReceived = content !== undefined;

  const [isVisible, setIsVisible] = useState(false);
  const [editorContent, setEditorContent] = useState<string>(defaultContent);
  const planCardEditorRef = useRef<any>();
  const loadContent = useCallback(() => {
    dispatch(getRichMediaContent(masterId, id, benefitKind));
  }, [dispatch, masterId, id, benefitKind]);

  const showAddContentBtn = useMemo(() => {
    return isEmpty(content) && isEditMode;
  }, [content, isEditMode]);

  const saveContent = (content: string) => {
    dispatch(updateRichMediaContent(masterId, id, content, benefitKind));
  };

  useEffect(() => {
    loadContent();
  }, [loadContent]);

  useEffect(() => {
    setEditorContent(content || defaultContent);
  }, [content]);

  const getDefaultRichMediaForSection = () => {
    let sectionName = SectionName.ACCIDENT;
    if (benefitKind === benefitCategory.CRITICAL_ILLNESS.value) {
      sectionName = SectionName.CRITICAL_ILLNESS;
    } else if (benefitKind === benefitCategory.HOSPITAL.value) {
      sectionName = SectionName.HOSPITAL;
    } else if (benefitKind === benefitCategory.CUSTOM_VOLUNTARY_BENEFIT.value) {
      sectionName = SectionName.CUSTOM_VOLUNTARY_BENEFIT;
    }
    if (sectionName && defaultRichMedia[sectionName]) {
      return defaultRichMedia[sectionName];
    }
    return '';
  };

  const handleResetDefault = () => {
    const defaultContent =
      benefitDescription || getDefaultRichMediaForSection();
    setEditorContent(defaultContent);
    planCardEditorRef.current.changeContent(defaultContent);
  };

  const setCurrentConetnt = () => {
    setEditorContent(content);
  };

  return (
    <div className={styles.volBenefitsCardWrapper}>
      <PlanCardContainer>
        <>
          {isTablet && <div className={styles.topShadow}></div>}
          <div className={styles.summaryWrapper}>
            <EditableHotspot
              alwaysVisible={false}
              editText="Edit"
              editIcon={<img src={iconEdit} alt="edit" />}
              useChannel={{
                client: upClient,
                channel: VOLUNTARY_BENEFITS_CHANNEL,
                event: OPEN_VOLUNTARY_BENEFITS_EDIT_MODAL,
                data: plan,
              }}
            >
              <div className={styles.summary}>
                <div className={styles.planHeaderWrapper}>
                  <div className={styles.logoWrapper}>
                    {plan.benefitCarrier && (
                      <img
                        src={plan.benefitCarrier.logoUrl}
                        alt={`${plan.benefitCarrier.name} logo`}
                        className={styles.logo}
                      />
                    )}
                  </div>
                  <TooltipPopover content={plan.name} placement="right">
                    <div className={styles.planName}>{plan.name}</div>
                  </TooltipPopover>
                  <div className={styles.volBenefitWrapper}>
                    <VoluntaryPlanTag />
                  </div>
                </div>
                {(!isEmpty(plan.documents) ||
                  !isEmpty(plan.additionalDocuments) ||
                  !isEmpty(plan.additionalWeblinks)) && (
                  <div
                    className={styles.viewDetailsLink}
                    onClick={() => setIsVisible(true)}
                  >
                    Plan Resources
                  </div>
                )}
              </div>
            </EditableHotspot>
          </div>
        </>
      </PlanCardContainer>
      <div
        className={
          showAddContentBtn ? styles.addTextWrapper : styles.description
        }
      >
        {!isContentReceived ? (
          <Spinner />
        ) : (
          <EditableHotspot
            className={showAddContentBtn ? styles.addTextHotspot : ''}
            alwaysVisible={showAddContentBtn}
            editText={showAddContentBtn ? '' : 'Edit'}
            editIcon={
              showAddContentBtn ? <></> : <img src={iconEdit} alt="edit" />
            }
            customModal={(visible: boolean, onClose: () => {}) => (
              <PlanCardEditor
                isOpenModal={visible}
                closeModal={onClose}
                editorContent={editorContent}
                isResetEnabled={true}
                resetToCurrentValue={() => {}}
                resetToDefault={handleResetDefault}
                defaultContent={getDefaultRichMediaForSection()}
                updateEditorContent={setEditorContent}
                benguideId={masterId}
                onSubmit={saveContent}
                ref={planCardEditorRef}
                setCurrentConetnt={setCurrentConetnt}
              />
            )}
          >
            {showAddContentBtn ? (
              <AddYourText />
            ) : (
              <div className={styles.content}>
                <ContentPreviewer
                  htmlContent={
                    isEmpty(editorContent) ? defaultContent : editorContent
                  }
                />
              </div>
            )}
          </EditableHotspot>
        )}
      </div>
      <ViewResourcesModal
        plan={plan}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        benefitKind={benefitKind}
      />
    </div>
  );
};
export default VoluntaryBenefitsCard;
